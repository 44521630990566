import React, { useState, useEffect, useRef } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  useMutation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Affix, Layout, Spin } from "antd";
import {
  AppHeader,
  Home,
  Host,
  Events,
  Login,
  NotFound,
  User,
} from "./sections";
import { AppHeaderSkeleton, ErrorBanner } from "./lib/components";
import { onError } from "apollo-link-error";
import { LOG_IN_AWS } from "./lib/graphql/mutations";
import {
  LogIn_AWS as LogInData,
  LogIn_AWSVariables as LogInVariables,
} from "./lib/graphql/mutations/LogIn_AWS/__generated__/LogIn_AWS";

import { Viewer } from "./lib/types";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";

import { createUploadLink } from "apollo-upload-client";
import RestartPassword from "./sections/ResetPassword";
import SimpleReporting from "./sections/SimpleReporting/SimpleReporting";
import MiHIN from "./sections/MiHIN/MiHIN";
import DataOverview from "./sections/DataOverview/DataOverview";
import HL7Logging from "./sections/HL7Logging/HL7Logging";
import Toast from "./core/components/Toast/Toast";
import { ToastContextProvidet } from "./core/components/Toast/ToastContext";

import "rsuite/dist/rsuite.min.css";

const apiUri = 'https://api.zilius-staging.zilius.ai' || "";
//console.log(apiUri);
//const apiUri = "http://localhost:8888";
const httpLink = createUploadLink({
  uri: apiUri,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("zilius-token");
  //console.log("token", token);
  return {
    headers: {
      ...headers,
      "X-CSRF-TOKEN": token || "",
    },
  };
});

const resetToken: any = onError((data) => {
  const GQLErrors = data.graphQLErrors;

  if (GQLErrors?.length) {
    GQLErrors.forEach((error) => {
      if (error?.extensions?.code === "UNAUTHENTICATED") {
        window.location.href = "/login";
        localStorage.removeItem("zilius-token");
      }
    });
  }
});

const authFlowLink = authLink.concat(resetToken);

const client = new ApolloClient({
  credentials: "include",
  link: authFlowLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const initialViewer: Viewer = {
  id: null,
  token: null,
  avatar: null,
  didRequest: false,
};

const App = () => {
  const [viewer, setViewer] = useState<Viewer>(initialViewer);
  const [logIn_aws, { error }] = useMutation<LogInData, LogInVariables>(
    LOG_IN_AWS,
    {
      onCompleted: (data) => {
        //console.log("useMutation: logIn_aws", data);
        if (data && data.logIn_aws) {
          setViewer(data.logIn_aws);
        }

        if (data.logIn_aws.token) {
          localStorage.setItem("zilius-token", data.logIn_aws.token);
        } else {
          localStorage.removeItem("zilius-token");
        }
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const logInRef = useRef(logIn_aws);

  useEffect(() => {
    //console.log("useeffect", logInRef);
    logInRef.current();
  }, []);

  if (!viewer.didRequest && !error) {
    return (
      <Layout className="app-skeleton">
        <AppHeaderSkeleton />
        <div className="app-skeleton__spin-section">
          <Spin size="large" tip="Launching Zilius" />
        </div>
      </Layout>
    );
  }
  const logInErrorBannerElement = error ? (
    <ErrorBanner description=" We weren't able to verify if you were logged in. Please try again later!" />
  ) : null;
  return (
    <>
      <Router>
        <Layout id="app">
          {logInErrorBannerElement}
          <Affix offsetTop={0} className="app__affix-header">
            <AppHeader viewer={viewer} setViewer={setViewer} />
          </Affix>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/host" component={Host} />
            <Route
              exact
              path="/login"
              render={(props) => (
                <Login {...props} viewer={viewer} setViewer={setViewer} />
              )}
            />
            <Route
              exact
              path="/user/:id"
              render={(props) => <User {...props} viewer={viewer} />}
            />
            <Route
              exact
              path="/events"
              render={(props) => <Events {...props} viewer={viewer} />}
              // render={(props) => <Events {...props} viewer={viewer} />}
            />
            <Route
              exact
              path="/simple-reporting"
              render={() => <SimpleReporting />}
            />

            <Route exact path="/mihin" render={() => <MiHIN />} />
            <Route
              exact
              path="/data-overview"
              render={() => <DataOverview />}
            />
            <Route exact path="/settings" render={() => <RestartPassword />} />
            <Route exact path="/hl7-logging" render={() => <HL7Logging />} />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Router>
      <Toast />
    </>
  );
};

render(
  <ApolloProvider client={client}>
    <ToastContextProvidet>
      <App />
    </ToastContextProvidet>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// v=f{R9i8GP07
